import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    filterConstants,
    LoadingAction,
    ErrorMessageAction,
    LoadQuestionsAction,
    LoadUsersAction,
    LoadLessonAction,
    LoadResponsesAction,
    LoadScheduleAction,
    LoadResponsePointsAction,
    LoadQuestionPracticeSkillsAction,
    LoadPracticeSkillsAction
} from "mindsets-js-sdk";

import ByQuestion from "./components/ByQuestion";
import ByStudent from "./components/ByStudent";
import ModeSelector from "./components/ModeSelector";
import Preview from "./components/Preview/index";
import Users from "./components/Users";
import FinishGradingButton from "./components/FinishGradingButton";

import './scoring.scss';

function getResponsePointsDebounced(getResponsePoints) {
    return _.debounce(getResponsePoints, 5 * 1000)
}

export default function () {
    const me = useSelector(state => state.me);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const [search_params, setSearchParams] = useSearchParams();
    const lesson = useSelector(state => state.lesson);
    const lesson_scoring_schedule = useSelector(state => state.lesson_scoring_schedule);
    const [mode, setMode] = useState(search_params.has('mode') ? search_params.get('mode') : 'question');
    const [membersOnline, setMembersOnline] = useState([]);

    function getResponsePoints() {
        dispatch(LoadResponsePointsAction({
            'filters[lesson_scoring_schedule_id][0]': filterConstants.EXPRESSION_EQUAL + '=' + params.schedule_id,
            'selectors[0]': 'response',
            'selectors[1]': 'with_reasoning',
            limit: 0
        }));
    }

    useEffect(() => {
        dispatch(LoadScheduleAction(params.schedule_id, {'selectors[1]': 'practice'}))
            .then(schedule => {
                dispatch(LoadResponsesAction({
                    'filters[lesson_id][0]': filterConstants.EXPRESSION_EQUAL + '=' + schedule.lesson_id,
                    'filters[created_at][0]': filterConstants.EXPRESSION_LT + '=' + new Date(schedule.created_at * 1000).toISOString(),
                    'filters[with_trashed][0]': filterConstants.EXPRESSION_TRUE,
                    limit: 0
                }));

                return dispatch(LoadLessonAction(schedule.lesson_id, {'selectors[0]': 'challenge_code', 'selectors[1]': 'streams_user'})).then(lesson => {
                    dispatch(LoadPracticeSkillsAction({
                        'filters[practice_id][0]': filterConstants.EXPRESSION_EQUAL + '=' + schedule.practice_id,
                        // 'filters[subject][0]': filterConstants.EXPRESSION_EQUAL + '=' + lesson.challenge_version.challenge_code.subject,
                        limit: 0
                    }));
                    dispatch(LoadQuestionPracticeSkillsAction({
                        'filters[challenge_code][0]': filterConstants.EXPRESSION_EQUAL + '=' + lesson.challenge_version.challenge_code.code,
                        'filters[practice_id][0]': filterConstants.EXPRESSION_EQUAL + '=' + schedule.practice_id,
                        'selectors[0]': 'points',
                        limit: 0
                    }));
                    dispatch(LoadQuestionsAction({
                        'filters[challenge_version_id][0]': filterConstants.EXPRESSION_EQUAL + '=' + lesson.challenge_version_id,
                        'selectors[0]': 'is_bonus',
                        'selectors[2]': 'poll_poll_options',
                        'selectors[3]': 'spreadsheet',
                        limit: 0
                    }));
                });
            })
            .catch(error => {
                dispatch(LoadingAction(false));
                dispatch(ErrorMessageAction(error));
                console.error(error);
            });
        getResponsePoints();
    }, []);
    useEffect(() => {
        const channel = pusher.subscribe('presence-grading-schedule-' + params.schedule_id);
        channel.bind('pusher:subscription_succeeded', data => setMembersOnline(Object.keys(data.members).filter(id => parseInt(id) !== me.id).map(id => parseInt(id))));
        channel.bind('pusher:subscription_error', error => {
            console.error(error);
        });
        channel.bind('pusher:member_added', member => {
            if (parseInt(member.id) !== me.id && !membersOnline.includes(parseInt(member.id))) {
                let membersOnline_new = JSON.parse(JSON.stringify(membersOnline));
                membersOnline_new.push(parseInt(member.id));
                setMembersOnline(membersOnline);
            }
        });
        channel.bind('pusher:member_removed', member => {
            let membersOnline_new = JSON.parse(JSON.stringify(membersOnline));
            if (membersOnline_new.includes(parseInt(member.id))) {
                membersOnline_new.splice(membersOnline.indexOf(parseInt(member.id)), 1);
                setMembersOnline(membersOnline);
            }
        });

        return () => channel.unsubscribe();
    }, []);
    useEffect(() => {
        if (membersOnline.length) {
            dispatch(LoadUsersAction({
                'filters[id_online][0]': filterConstants.EXPRESSION_IN + '=' + membersOnline.join(',')
            }));
        }
    }, [membersOnline]);

    if (!me || !(lesson && Object.keys(lesson).length > 0) || !lesson_scoring_schedule) {
        return null;
    }

    return (
        <>
            <h1 className='container'>Students scoring for the class {lesson.code.toUpperCase()} of the
                v{lesson.challenge_version.version} of the
                challenge {lesson.challenge_version.name} by a Rubric based
                on {lesson_scoring_schedule.practice.name} set of skills</h1>
            <div className='container d-flex justify-content-between'>
                <button className="button" onClick={event => {
                    event.preventDefault();
                    navigate(-1);
                }}>{'<<'} Back
                </button>
                <Link
                    to={`/challenges/${lesson.challenge_version.challenge_code.code}/practice/${lesson_scoring_schedule.practice_id}/preview`}
                    target='_blank' className="button">Open Rubric</Link>
                <FinishGradingButton/>
            </div>
            <ModeSelector mode={mode} setMode={mode => {
                setMode(mode);
                search_params.set('mode', mode);
                setSearchParams(search_params);
            }}/>
            <Users/>
            <ByQuestion mode={mode} getResponsePoints={getResponsePointsDebounced(getResponsePoints)}/>
            <ByStudent mode={mode} getResponsePoints={getResponsePointsDebounced(getResponsePoints)}/>
            <Preview mode={mode}/>
        </>
    );
}
