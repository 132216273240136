import React from 'react';
import PropTypes from "prop-types";

import DeleteButton from "./DeleteButton";
import EditButton from "./EditButton";
import InvalidateButton from "./InvalidateButton";

function Row({item}) {
    function renderProjectOrg() {
        let org = 'n/a';
        if (item.project.district && item.project.district.name) {
            org = 'District name: ' + item.project.district.name;
        } else if (item.project.school && item.project.school.name) {
            org = 'School name: ' + item.project.school.name;
        }

        return (
            <div>{org}</div>
        );
    }

    return (
        <tr>
            <td>{item.id}</td>
            <td>{item.title}</td>
            <td>
                <div>Project name: <strong>{item.project.name}</strong></div>
                {renderProjectOrg()}
            </td>
            <td>{item.project.district && item.project.district.state ? item.project.district.state : (item.project.school && item.project.school.state ? item.project.school.state : 'n/a')}</td>
            <td>{item.type}</td>
            <td>{Array.isArray(item.practices) ? item.practices.map(practice => <div key={practice}>{practice}</div>) : ''}</td>
            <td>
                <div className='d-flex flex-gap-5'>
                    <EditButton id={item.id}/>
                    <DeleteButton id={item.id}/>
                    <InvalidateButton id={item.id}/>
                </div>
            </td>
        </tr>
    );
}

Row.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        project: PropTypes.shape({
            name: PropTypes.string.isRequired,
            district: PropTypes.shape({
                name: PropTypes.string.isRequired,
                state: PropTypes.string.isRequired
            }),
            school: PropTypes.shape({
                name: PropTypes.string.isRequired,
                state: PropTypes.string.isRequired
            })
        }).isRequired,
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        practices: PropTypes.arrayOf(PropTypes.string)
    })
};

export default Row;
