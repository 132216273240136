import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {HTMLContent, contentUtils, authUtils, accessConstants, ResponseContent} from "mindsets-js-sdk";

import ScoreInput from "./ScoreInput";
import NavigationButtons from "./NavigationButtons";

function ByStudent({mode, getResponsePoints}) {
    const [search_params] = useSearchParams();
    const me = useSelector(state => state.me);
    const schedule = useSelector(state => state.lesson_scoring_schedule);
    const questions = useSelector(state => state.questions);
    const lesson = useSelector(state => state.lesson);
    const question_practice_skills = useSelector(state => state.question_practice_skills);
    const practice_skills = useSelector(state => state.practice_skills);
    const response_points = useSelector(state => state.response_points);
    const responses = useSelector(state => state.responses);
    const [index, setIndex] = useState(search_params.has('si') ? parseInt(search_params.get('si')) : 0);
    const stream_ids = [];
    response_points.map(response_point => {
        if (!stream_ids.includes(response_point.stream_id)) {
            stream_ids.push(response_point.stream_id)
        }
    });

    function renderQuestionPracticeSkills(stream_id, question) {
        const question_practice_skills_for_question = question_practice_skills.filter(qps => qps.question_code === question.code);
        if (question_practice_skills_for_question.length > 0) {
            return question_practice_skills_for_question.map(question_practice_skill => {
                const response_point_index = response_points.findIndex(response_point => response_point.stream_id === stream_id && response_point.question_id === question.id && response_point.practice_skill_id === question_practice_skill.practice_skill_id);
                const response_point = response_points[response_point_index];
                const practice_skill = practice_skills.find(practice_skill => practice_skill.id === question_practice_skill.practice_skill_id);

                let color = '';
                if (response_point && response_point.use_auto === 'using_ai') {
                    color = 'bg-blue';
                } else if (response_point && response_point.use_auto === 'using') {
                    color = 'bg-grey';
                }
                const max_points = question_practice_skill && question_practice_skill.points ? question_practice_skill.points.length - 1 : null;
                if (response_point && response_point.points > max_points) {
                    color = 'bg-danger';
                }

                return (
                    <tr key={question_practice_skill.id} className={color}>
                        <td>
                            <div>
                                <strong>Skill: {practice_skill ? (practice_skill.code + ' ' + practice_skill.name) : 'Not defined'}</strong>
                            </div>
                            <HTMLContent
                                html_str={contentUtils.processStringContent('Example solution: ' + (question_practice_skill ? question_practice_skill.example_solution : 'Not defined'), {convert_spelling: false})}/>
                        </td>
                        <td>
                            <ScoreInput
                                question_practice_skill={question_practice_skill}
                                getResponsePoints={getResponsePoints}
                                response_point_index={response_point_index}
                            />
                        </td>
                    </tr>
                );
            });
        } else {
            return (
                <tr key={index}>
                    <td>No rubric for this question</td>
                </tr>
            );
        }
    }

    function renderQuestions(stream_id) {
        return questions.map(question => {
            let response = null;
            const stream_responses = JSON.parse(JSON.stringify(responses)).filter(response => response.stream_id === stream_id && response.question_id === question.id).reverse();
            if (stream_responses.length > 0) {
                response = stream_responses[0];
            }

            return (
                <tr key={question.id}>
                    <td>
                        <strong>
                            <HTMLContent
                                html_str={contentUtils.processStringContent((question.number ? 'Q' + question.number : '') + (question.is_bonus ? '(BONUS)' : '') + ' ' + question.title, {convert_spelling: false})}/>
                        </strong>
                        <div>Answer type: {question.answer_type}</div>
                    </td>
                    <td>
                        <ResponseContent
                            question={question}
                            response={response && response.data ? response.data.answer : null}/>
                    </td>
                    <td className='paddingless'>
                        <table className="table table-hover table-sm">
                            <tbody>
                            {renderQuestionPracticeSkills(stream_id, question)}
                            </tbody>
                        </table>
                    </td>
                </tr>
            );
        })
    }

    if (!schedule) {
        return null;
    }
    if (!lesson) {
        return null;
    }

    if ((schedule.status !== 'finished' || authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MASTER_RUBRICS])) && mode === 'student') {
        const stream_id = stream_ids[index];
        if (!stream_id) {
            return null;
        }
        const stream = lesson.streams.find(stream => stream.id === stream_id);
        if (!stream) {
            return null;
        }

        return (
            <div>
                <div className='container'>
                    <NavigationButtons
                        setIndex={setIndex}
                        index={index}
                        length={stream_ids.length}
                        index_key='si'/>
                </div>
                <div>
                    <div className='d-flex justify-content-between'>
                        <div>{'Stream ID ' + stream_id}</div>
                        <div>{'Student ID ' + stream.user.id}</div>
                        <div>{stream.user.fname + ' ' + stream.user.lname}</div>
                        <div className='d-flex justify-content-end flex-gap-5'>
                            <div className='d-flex flex-gap-10'>
                                <div className='badge bg-warning'>NR</div>
                                <div>No response from student</div>
                            </div>
                            <div className='d-flex'>
                                <div className='badge bg-grey'>Responses automatically graded by our system</div>
                            </div>
                            <div className='d-flex'>
                                <div className='badge bg-blue'>Responses automatically graded by AI</div>
                            </div>
                            <div className='d-flex flex-gap-10'>
                                <div className='badge bg-danger'>Score exceeds max points
                                </div>
                            </div>
                            <div className='d-flex flex-gap-10'>
                                <div className='badge bg-info'>NA</div>
                                <div>No response or non-gradable</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="table-responsive mb-4">
                            <table className='table table-bordered table-sm'>
                                <thead>
                                <tr>
                                    <th>Question</th>
                                    <th>Answer</th>
                                    <th>Score</th>
                                </tr>
                                </thead>
                                <tbody>
                                {renderQuestions(stream_id)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return null;
}

ByStudent.propTypes = {
    mode: PropTypes.string.isRequired,
    getResponsePoints: PropTypes.func.isRequired
};

export default ByStudent;
