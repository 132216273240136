import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import MindsetsJsSDK, {
    accessConstants,
    authUtils, Autocomplete, Checkbox,
    ErrorMessageAction, filterConstants,
    LoadedAutocompleteItemsAction,
    LoadedAutocompleteTotalsAction,
    LoadingAction, LoadPracticesAction
} from "mindsets-js-sdk";


import NoAccess from "../../common/NoAccess";
import SubmitButtons from "./components/SubmitButtons";
import {CreateDashboardAction} from "../../actions/dashboard_actions";

export default function () {
    const me = useSelector(state => state.me);
    const practices = useSelector(state => state.practices);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [dashboard, setDashboard] = useState({});
    const [updated, setUpdated] = useState(false);

    const validate = function () {
        let errors = 0;

        if (!dashboard.title || dashboard.title === '') {
            dispatch(ErrorMessageAction('Error! Please specify the title of the dashboard.'));
            errors++;
        }

        if (!dashboard.type || dashboard.type === '') {
            dispatch(ErrorMessageAction('Error! Please select type.'));
            errors++;
        }

        if (!dashboard.project_id || dashboard.project_id === '') {
            dispatch(ErrorMessageAction('Error! Please select Project ID.'));
            errors++;
        }
        if (dashboard.class_codes) {
            dashboard.class_codes.split(',').map(class_code => {
                if (!/^[a-fA-F]{4}[0-9]{2}$/.test(class_code.trim())) {
                    dispatch(ErrorMessageAction('Invalid Class Code format: ' + class_code));
                    errors++;
                }
            });
        }
        if (!dashboard.practices || dashboard.practices.length === 0) {
            dispatch(ErrorMessageAction('Error! Please select at least one Practice.'));
            errors++;
        }

        return errors === 0;
    }

    useEffect(() => {
        dispatch(LoadPracticesAction());

        return () => setDashboard(null);
    }, []);

    const debounce = useCallback(_.debounce(a => a(), 600), []);

    if (!me) {
        return null;
    }
    if (!practices.length) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_MANAGE_CONTRACTS])) {
        return (
            <NoAccess/>
        );
    }

    return (
        <div className='container section'>
            <h1>Create new Dashboard {dashboard.title ? dashboard.title : ''}</h1>
            <form onSubmit={event => {
                event.preventDefault();
                if (validate()) {
                    dispatch(CreateDashboardAction(dashboard))
                        .then(() => navigate(-1))
                        .catch(error => {
                            dispatch(LoadingAction(false));
                            dispatch(ErrorMessageAction(error));
                            console.error(error);
                        });
                }
            }} autoComplete="off" id="dashboard-form">
                <div className="row">
                    <label htmlFor="Project" className="col-sm-2 col-form-label">Project *</label>
                    <div className="col-sm-10">
                        <Autocomplete
                            id='dashboard-projects'
                            placeholder='Project name'
                            fetchItems={name => {
                                debounce(function () {
                                    MindsetsJsSDK().API.get('projects', {
                                        'filters[name][0]': filterConstants.EXPRESSION_CONTAINS + '=' + name
                                    })
                                        .then(response => {
                                            dispatch(LoadedAutocompleteItemsAction('dashboard-projects', response.items));
                                            dispatch(LoadedAutocompleteTotalsAction('dashboard-projects', response.items.length));
                                        })
                                        .catch(error => {
                                            console.error(error);
                                            dispatch(ErrorMessageAction('Failed loading projects'));
                                        });
                                });
                            }}
                            onSelect={project => {
                                let dashboard_new = JSON.parse(JSON.stringify(dashboard));
                                if (project) {
                                    dashboard_new.project_id = project.id;
                                } else {
                                    dashboard_new.project_id = null;
                                }
                                setDashboard(dashboard_new);
                                if (!updated) {
                                    setUpdated(true);
                                }

                                return project ? project.name : '';
                            }}
                            renderItem={project => (
                                <div className="autocomplete-dropdown-menu-item-title">{project.name}</div>)}
                            persist_value={true}
                            show_counts={true}
                        />
                    </div>
                </div>
                <div className="row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">Title *</label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            placeholder="Title"
                            value={dashboard.title ? dashboard.title : ''}
                            onChange={event => {
                                let dashboard_new = JSON.parse(JSON.stringify(dashboard));
                                dashboard_new.title = event.target.value;
                                setDashboard(dashboard_new);
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}/>
                    </div>
                </div>
                <div className="row">
                    <label htmlFor="type" className="col-sm-2 col-form-label">Type *</label>
                    <div className="col-sm-10">
                        <select
                            className="form-control"
                            id="type"
                            value={dashboard.type ? dashboard.type : ''}
                            onChange={event => {
                                let dashboard_new = JSON.parse(JSON.stringify(dashboard));
                                dashboard_new.type = event.target.value;
                                setDashboard(dashboard_new);
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}
                        >
                            <option value='' disabled={true}>SELECT TYPE</option>
                            <option value='TYPE 1'>TYPE 1</option>
                            <option value='TYPE 2'>TYPE 2</option>
                            <option value='TYPE 3'>TYPE 3</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <label htmlFor="description" className="col-sm-2 col-form-label">Description(Optional)</label>
                    <div className="col-sm-10">
                        <textarea
                            className="form-control"
                            id="description"
                            placeholder="Description"
                            value={dashboard.description ? dashboard.description : ''}
                            onChange={event => {
                                let dashboard_new = JSON.parse(JSON.stringify(dashboard));
                                dashboard_new.description = event.target.value;
                                setDashboard(dashboard_new);
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}/>
                    </div>
                </div>
                <div className="row">
                    <label htmlFor="practices" className="col-sm-2 col-form-label">Practices *</label>
                    <div className="col-sm-10">
                        <div className='d-flex flex-gap-10 flex-wrap'>
                            {practices.map((practice, i) => {
                                return (
                                    <Checkbox
                                        key={practice.id}
                                        checked={Array.isArray(dashboard.practices) && dashboard.practices.includes(practice.code)}
                                        label={practice.name}
                                        toggle={() => {
                                            let dashboard_new = JSON.parse(JSON.stringify(dashboard));
                                            if (!dashboard_new.practices) {
                                                dashboard_new.practices = [];
                                            }
                                            const index = dashboard_new.practices.findIndex(practice_code => practice_code === practice.code);
                                            if (index >= 0) {
                                                dashboard_new.practices.splice(index, 1);
                                            } else {
                                                dashboard_new.practices.push(practice.code);
                                            }
                                            setDashboard(dashboard_new);
                                            if (!updated) {
                                                setUpdated(true);
                                            }
                                        }}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <label htmlFor="class_codes" className="col-sm-2 col-form-label">Class Codes(Optional)</label>
                    <div className="col-sm-10">
                        <textarea
                            className="form-control"
                            id="class_codes"
                            placeholder="Class Codes"
                            value={dashboard.class_codes ? dashboard.class_codes : ''}
                            onChange={event => {
                                let dashboard_new = JSON.parse(JSON.stringify(dashboard));
                                dashboard_new.class_codes = event.target.value ? event.target.value : null;
                                setDashboard(dashboard_new);
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}/>
                    </div>
                </div>

                <SubmitButtons updated={updated}/>
            </form>
        </div>
    );
}
