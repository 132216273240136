import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {
    accessConstants,
    filterConstants,
    authUtils,
    ErrorMessageAction,
    LoadQuestionsAction,
    LoadChallengeCodeAction,
    LoadPollsAction,
    LoadedChallengeCodeAction,
    LoadedQuestionsAction,
    LoadedPollsAction,
    LoadedPracticesAction,
    LoadPracticesAction,
    LoadQuestionPracticeSkillsAction
} from "mindsets-js-sdk";

import {
    SetSelectedAssignmentIndexAction,
    SetSelectedPracticeIdAction
} from "../../actions/practice_actions";
import {SetQuestionPracticeSkillsUpdatedAction} from "../../actions/question_actions";
import NoAccess from "../../common/NoAccess";
import UpdateRubricForm from "./UpdateRubricForm";

import './rubric_manager.scss';
import {LoadStandardTopicsAction} from "../../actions/standard_actions";

export default function () {
    const me = useSelector(state => state.me);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const challenge_code = useSelector(state => state.challenge_code);
    const practices = useSelector(state => state.practices);
    const questions = useSelector(state => state.questions);
    const [selected_question_index, setSelectedQuestionIndex] = useState(0);
    const question_practice_skills = useSelector(state => state.question_practice_skills);
    const question_practice_skills_updated = useSelector(state => state.question_practice_skills_updated);
    const selected_practice_id = useSelector(state => state.selected_practice_id);
    const selected_assignment_index = useSelector(state => state.selected_assignment_index);
    const practice_id = searchParams.get('practice_id');
    const container_ref = useRef();

    const question = questions[selected_question_index];
    let question_in_sync = questionInSync(question);

    function loadQuestionPracticeSkills(practice_id, question_code) {
        return dispatch(LoadQuestionPracticeSkillsAction({
            'filters[challenge_code][0]': filterConstants.EXPRESSION_EQUAL + '=' + params.challenge_code,
            'filters[practice_id][0]': filterConstants.EXPRESSION_EQUAL + '=' + practice_id,
            'filters[question_code][0]': filterConstants.EXPRESSION_EQUAL + '=' + question_code,
            'selectors[0]': 'points',
            'selectors[1]': 'questions',
            'selectors[2]': 'practice_id',
            limit: 0
        }))
            .then(question_practice_skills => {
                dispatch(SetQuestionPracticeSkillsUpdatedAction(JSON.parse(JSON.stringify(question_practice_skills))));
                if (question_practice_skills.length > 0) {
                    dispatch(SetSelectedAssignmentIndexAction(0));
                } else {
                    dispatch(SetSelectedAssignmentIndexAction(null));
                }
            });
    }

    function questionInSync(question) {
        if (isNaN(parseInt(selected_assignment_index))) {
            return true;
        }
        if (!selected_practice_id) {
            return true;
        }
        let question_practice_skill = question_practice_skills[selected_assignment_index];
        if (!question_practice_skill) {
            return false;
        }
        const skill_updated = question_practice_skills_updated[selected_assignment_index];
        if (!skill_updated) {
            return true;
        }

        if (question_practice_skill.id !== skill_updated.id) {
            return false;
        }
        if (question_practice_skill.practice_skill_id !== skill_updated.practice_skill_id && skill_updated.practice_skill_id || (!question_practice_skill.practice_skill_id && skill_updated.practice_skill_id || question_practice_skill.practice_skill_id && !skill_updated.practice_skill_id)) {
            return false;
        }
        if (question_practice_skill.dok !== skill_updated.dok && skill_updated.dok || (!question_practice_skill.dok && skill_updated.dok || question_practice_skill.dok && !skill_updated.dok)) {
            return false;
        }
        if (question_practice_skill.skill !== skill_updated.skill && skill_updated.skill || (!question_practice_skill.skill && skill_updated.skill || question_practice_skill.skill && !skill_updated.skill)) {
            return false;
        }
        if (question_practice_skill.example_solution !== skill_updated.example_solution && skill_updated.example_solution || (!question_practice_skill.example_solution && skill_updated.example_solution || question_practice_skill.example_solution && !skill_updated.example_solution)) {
            return false;
        }
        if (question_practice_skill.domain !== skill_updated.domain && skill_updated.domain || (!question_practice_skill.domain && !!skill_updated.domain || !!question_practice_skill.domain && !skill_updated.domain)) {
            return false;
        }
        if (Array.isArray(question_practice_skill.questions) && Array.isArray(skill_updated.questions)) {
            const skill_questions = JSON.parse(JSON.stringify(question_practice_skill.questions));
            const skill_updated_questions = JSON.parse(JSON.stringify(skill_updated.questions));
            if (skill_questions.sort().join('|') !== skill_updated_questions.sort().join('|')) {
                return false;
            }
        } else if (question_practice_skill.questions && question_practice_skill.questions.length !== skill_updated.questions.length) {
            return false;
        }
        if (question_practice_skill.notes !== skill_updated.notes && skill_updated.notes || (!question_practice_skill.notes && !!skill_updated.notes || !!question_practice_skill.notes && !skill_updated.notes)) {
            return false;
        }

        if (['answer.choice_single', 'answer.choice_multiple', 'poll.inline', 'answer.expression', 'answer.expression_multi'].includes(question.answer_type)) {
            // Must be an array
            if (Array.isArray(question_practice_skill.correct_answer) !== Array.isArray(skill_updated.correct_answer)) {
                return false;
            }
            if (Array.isArray(question_practice_skill.correct_answer) && Array.isArray(skill_updated.correct_answer) && question_practice_skill.correct_answer.length !== skill_updated.correct_answer.length) {
                return false;
            }
            // FOR INLINE POLL?????? TODO investigate more
            if (Array.isArray(question_practice_skill.correct_answer) && Array.isArray(skill_updated.correct_answer) && question_practice_skill.correct_answer.map(o => o.points ? o.points.toString() : null).sort().join('|') !== skill_updated.correct_answer.map(o => o.points ? o.points.toString() : null).sort().join('|')) {
                return false;
            }
        } else if (['answer.slider', 'answer.range', 'answer.number_line', 'answer.dnd', 'answer.selector', 'answer.matching', 'answer.fraction', 'answer.dnd_puzzle', 'answer.number_only'].includes(question.answer_type)) {
            // Must be an object
            if (JSON.stringify(Object.fromEntries(Object.entries(question_practice_skill.correct_answer || {}).sort())) !== JSON.stringify(Object.fromEntries(Object.entries(skill_updated.correct_answer || {}).sort()))) {
                return false;
            }
        } else if (['answer.textarea', 'answer.graphing', 'spreadsheet'].includes(question.answer_type)) {
            // Skipp
        } else {
            throw 'Unsupported answer type for comparison: ' + question.answer_type;
        }

        if (question_practice_skill.points.length !== skill_updated.points.length) {
            return false;
        } else {
            const result = question_practice_skill.points.filter((point, index) => {
                let is_true = true;
                const point2 = skill_updated.points[index];
                if (point2.description !== point.description) {
                    is_true = false;
                }
                if (Array.isArray(point2.example_responses) !== Array.isArray(point.example_responses)) {
                    is_true = false;
                }
                if (Array.isArray(point2.example_responses) && Array.isArray(point.example_responses) && point2.example_responses.length !== point.example_responses.length) {
                    is_true = false;
                }
                if (Array.isArray(point2.example_responses) && Array.isArray(point.example_responses)) {
                    const example_responses_updated_sorted = JSON.parse(JSON.stringify(point2.example_responses)).sort();
                    const example_responses_sorted = JSON.parse(JSON.stringify(point.example_responses)).sort();
                    if (example_responses_updated_sorted.join('|') !== example_responses_sorted.join('|')) {
                        is_true = false;
                    }
                }

                return !is_true;
            });
            if (result.length) {
                return false;
            }
        }

        return true;
    }

    useEffect(() => {
        dispatch(LoadStandardTopicsAction());
        dispatch(LoadChallengeCodeAction(params.challenge_code, {'selectors[0]': 'latest_challenge_version_name'}));
        dispatch(LoadPracticesAction({'selectors[0]': 'practice_skills'})).then(() => {
            dispatch(LoadQuestionsAction({
                'selectors[0]': 'by_code',
                'selectors[1]': 'answer_component_data',
                'selectors[2]': 'is_bonus',
                'filters[challenge_code][0]': filterConstants.EXPRESSION_EQUAL + '=' + params.challenge_code,
                order_by: 'number',
                limit: 0
            })).then(questions => {
                if (practice_id) {
                    loadQuestionPracticeSkills(practice_id, questions[selected_question_index].code);
                }
            });
        });
        dispatch(LoadPollsAction({
            'filters[challenge_code][0]': filterConstants.EXPRESSION_EQUAL + '=' + params.challenge_code,
            'filters[type][0]': filterConstants.EXPRESSION_EQUAL + '=inline',
            'selectors[0]': 'poll_options',
            'limit': 0
        }));

        return () => {
            dispatch(SetQuestionPracticeSkillsUpdatedAction([]));
            dispatch(LoadedPracticesAction([]));
            dispatch(LoadedChallengeCodeAction(null));
            dispatch(LoadedQuestionsAction([]));
            dispatch(LoadedPollsAction([]));
            dispatch(SetSelectedPracticeIdAction(null));
        }
    }, []);
    useEffect(() => {
        if (!isNaN(parseInt(practice_id))) {
            dispatch(SetSelectedPracticeIdAction(parseInt(practice_id)));
            if (searchParams.has('practice_id')) {
                searchParams.delete('practice_id');
                setSearchParams(searchParams);
            }
        }
    }, [practice_id]);

    useEffect(() => {
        if (container_ref.current) {
            const container_rect = container_ref.current.getBoundingClientRect();
            const element = document.getElementById('question-item-' + selected_question_index);
            if (element) {
                const item_rect = element.getBoundingClientRect();
                if (item_rect.right > container_rect.right) {
                    container_ref.current.scrollBy({left: container_rect.width / 2 + (item_rect.right - container_rect.right)});
                } else if (item_rect.left < container_rect.left) {
                    container_ref.current.scrollBy({left: -(container_rect.width / 2 + (container_rect.left - item_rect.left))});
                }
            }
        }
    }, [selected_question_index]);

    if (!me) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_MANAGE_RUBRICS])) {
        return (
            <NoAccess/>
        );
    }
    if (!challenge_code || !practices.length || !questions.length) {
        return null;
    }

    return (
        <div className='container section' id='rubric-manager-page'>
            <h1>Updating questions of the challenge {challenge_code.latest_challenge_version_name}</h1>
            <div className='d-flex justify-content-between my-2'>
                <button className="button" onClick={event => {
                    event.preventDefault();
                    navigate(-1);
                }}>{'<< '}Back
                </button>
                <a
                    href={process.env.PUBLIC_APP_URL + '/challenge/' + challenge_code.code + '/guide.pdf'}
                    className='button'
                    target='_blank'
                >Teacher Guide</a>
            </div>
            <div>
                <div className='d-flex questions-list mx-n1 my-2' ref={container_ref}>
                    {questions.map((question_x, index) => {
                        return (
                            <div
                                key={index}
                                id={'question-item-' + index}
                                className={'bordered mx-1 p-1 clickable text-center question-item ' + (selected_question_index === index ? 'selected' : '')}
                                onClick={() => {
                                    if (question_in_sync) {
                                        Promise.resolve()
                                            .then(() => setSelectedQuestionIndex(index))
                                            .then(() => {
                                                if (selected_practice_id) {
                                                    loadQuestionPracticeSkills(selected_practice_id, question_x.code)
                                                }
                                            })
                                    } else {
                                        dispatch(ErrorMessageAction('You have unsaved data. Save it before moving to the next question.'));
                                    }
                                }}
                            >
                                <div>
                                    <strong>{(question_x.number ? 'Q' + question_x.number : '') + (question_x.is_bonus ? '(BONUS)' : '')}</strong>
                                </div>
                                <div className='small'>{question_x.code}</div>
                                <div>{question_x.answer_type.split('.').join(' ').split('_').join(' ')}</div>
                            </div>
                        );
                    })}
                </div>
                <div className={'text-center ' + (question_in_sync ? 'text-success' : 'text-danger')}>
                    {!question_in_sync ? 'Q[' + (question.code + '] updated but not saved') : 'All question rubrics are in sync'}
                </div>
                <div className='d-flex flex-gap-5'>
                    {practices.map(practice => {
                        return (
                            <div
                                key={practice.id}
                                className={'bordered p-2 clickable hoverable' + (practice.id === selected_practice_id ? ' selected' : '')}
                                onClick={() => {
                                    if (question_in_sync) {
                                        Promise.resolve()
                                            .then(() => dispatch(SetSelectedPracticeIdAction(practice.id)))
                                            .then(() => loadQuestionPracticeSkills(practice.id, question.code))
                                    } else {
                                        dispatch(ErrorMessageAction('You have unsaved data. Save it before moving to the next Practice.'));
                                    }
                                }}>{practice.name}</div>
                        );
                    })}
                </div>
                <UpdateRubricForm
                    id={question.code}
                    question={question}
                    key={question.code}
                    loadQuestionPracticeSkills={() => loadQuestionPracticeSkills(selected_practice_id, question.code)}
                    updated={!question_in_sync}
                />
            </div>
        </div>
    );
}
