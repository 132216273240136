export const SET_SELECTED_PRACTICE_ID = '[practice] Set Selected Practice ID';
export const SET_SELECTED_ASSIGNMENT_INDEX = '[practice] Set Selected Assignment Index';

export const SetSelectedPracticeIdAction = payload => {
    return {
        type: SET_SELECTED_PRACTICE_ID,
        payload
    };
};
export const SetSelectedAssignmentIndexAction = payload => {
    return {
        type: SET_SELECTED_ASSIGNMENT_INDEX,
        payload
    };
};
