import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {
    accessConstants,
    filterConstants,
    authUtils,
    HTMLContent,
    contentUtils,
    GetRubricPDFDataAction,
    LoadChallengeCodeAction,
    LoadQuestionsAction,
    LoadPollsAction,
    LoadedChallengeCodeAction,
    LoadedQuestionsAction,
    LoadedPollsAction,
    LoadQuestionPracticeSkillsAction,
    LoadedPracticeAction,
    LoadPracticeAction
} from "mindsets-js-sdk";

import NoAccess from "../../common/NoAccess";
import EditButton from "./components/EditButton";
import CorrectAnswer from "./components/CorrectAnswer";
import Summary from "./components/Summary";
import SummaryBonus from "./components/SummaryBonus";

export default function () {
    const me = useSelector(state => state.me);
    const dispatch = useDispatch();
    const params = useParams();
    const challenge_code = useSelector(state => state.challenge_code);
    const practice = useSelector(state => state.practice);
    const questions = useSelector(state => state.questions);
    const question_practice_skills = useSelector(state => state.question_practice_skills);
    useEffect(() => {
        dispatch(LoadChallengeCodeAction(params.challenge_code, {'selectors[0]': 'latest_challenge_version_name'}));
        dispatch(LoadPracticeAction(params.practice_code, {'selectors[0]': 'practice_skills'})).then(practice => {
            dispatch(LoadQuestionPracticeSkillsAction({
                'filters[challenge_code][0]': filterConstants.EXPRESSION_EQUAL + '=' + params.challenge_code,
                'filters[practice_id][0]': filterConstants.EXPRESSION_EQUAL + '=' + practice.id,
                'selectors[0]': 'points',
                'selectors[1]': 'questions',
                limit: 0
            }));
        });
        dispatch(LoadQuestionsAction({
            'selectors[0]': 'by_code',
            'filters[challenge_code][0]': filterConstants.EXPRESSION_EQUAL + '=' + params.challenge_code,
            order_by: 'number'
        }));
        dispatch(LoadPollsAction({
            'filters[challenge_code][0]': filterConstants.EXPRESSION_EQUAL + '=' + params.challenge_code,
            'filters[type][0]': filterConstants.EXPRESSION_EQUAL + '=inline',
            'selectors[0]': 'poll_options',
            'limit': 0
        }));

        return () => {
            dispatch(LoadedPracticeAction(null));
            dispatch(LoadedChallengeCodeAction(null));
            dispatch(LoadedQuestionsAction([]));
            dispatch(LoadedPollsAction([]));
        }
    }, []);
    if (!me) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_SEE_RUBRICS])) {
        return (
            <NoAccess/>
        );
    }
    if (!challenge_code || !practice || !question_practice_skills.length || !questions.length) {
        return null;
    }

    return (
        <div className='container section'>
            <h1>{practice.name} Rubric for the challenge {challenge_code.latest_challenge_version_name}</h1>
            <div className='d-flex justify-content-end flex-gap-5'>
                <EditButton/>
                <button
                    className='button'
                    onClick={event => {
                        event.preventDefault();
                        dispatch(GetRubricPDFDataAction(params.challenge_code, params.practice_code)).then(buffer => {
                            let a = document.createElement('a');
                            const blob = new Blob([buffer], {'type': 'application/pdf'});
                            a.href = window.URL.createObjectURL(blob);
                            a.download = challenge_code.latest_challenge_version_name + ' rubric.pdf';
                            a.click();
                            window.close();
                        });
                    }}>Download PDF
                </button>
            </div>
            <div>
                {questions.map(question => {
                    const question_practice_skills_for_question = question_practice_skills.filter(qps => qps.question_code === question.code);
                    if (question_practice_skills_for_question.length) {
                        const max_points = Math.max(...question_practice_skills_for_question.map(qps => qps.points.length));
                        const trs = [];
                        for (let i = 0; i < max_points; i++) {
                            trs.push((
                                <tr key={i}>
                                    <td><strong>{i} point{i !== 1 ? 's' : ''}</strong></td>
                                    {question_practice_skills_for_question.map(question_practice_skill => {
                                        const point = question_practice_skill.points[i];

                                        return (
                                            <td key={question_practice_skill.id}>{point ? point.description : <div className='badge bg-info'>Not Applicable</div>}</td>
                                        );
                                    })}
                                </tr>
                            ));
                        }

                        return (
                            <div className="table-responsive mb-4" key={question.id}>
                                <table className='table table-bordered table-sm'>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <strong>
                                                <HTMLContent
                                                    html_str={contentUtils.processStringContent((question.number ? 'Q' + question.number : '') + (question.is_bonus ? '(BONUS)' : '') + ' ' + question.title, {convert_spelling: false})}/>
                                            </strong>
                                            <div>{question.answer_type}</div>
                                        </td>
                                        {question_practice_skills_for_question.map(question_practice_skill => {
                                            const practice_skill = practice.practice_skills.find(practice_skill => question_practice_skill.practice_skill_id === practice_skill.id);
                                            return (
                                                <td key={question_practice_skill.id}>
                                                    <strong>{practice_skill.code + ' ' + practice_skill.name}</strong>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                    <tr>
                                        <td><strong>DOK</strong></td>
                                        {question_practice_skills_for_question.map(question_practice_skill => {
                                            return (
                                                <td key={question_practice_skill.id}>{question_practice_skill.dok}</td>
                                            );
                                        })}
                                    </tr>
                                    <tr>
                                        <td><strong>Skill</strong></td>
                                        {question_practice_skills_for_question.map(question_practice_skill => {
                                            return (
                                                <td key={question_practice_skill.id}>{question_practice_skill.skill}</td>
                                            );
                                        })}
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Example solution</strong>
                                        </td>
                                        {question_practice_skills_for_question.map(question_practice_skill => {
                                            return (
                                                <td key={question_practice_skill.id}>
                                                    <div
                                                        dangerouslySetInnerHTML={{__html: question_practice_skill.example_solution}}
                                                        style={{'whiteSpace': 'pre-wrap'}}/>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                    <tr>
                                        <td><strong>Depends on</strong></td>
                                        {question_practice_skills_for_question.map(question_practice_skill => {
                                            return (
                                                <td key={question_practice_skill.id}>
                                                    {Array.isArray(question_practice_skill.questions) && question_practice_skill.questions.length ? questions.filter(q => question_practice_skill.questions.includes(q.id)).map(q => 'Q' + q.number).join(', ') : 'Is not dependant'}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                    <tr>
                                        <td><strong>Single Correct Answer</strong></td>
                                        {question_practice_skills_for_question.map(question_practice_skill => {
                                            return (
                                                <td key={question_practice_skill.id}>
                                                    <CorrectAnswer
                                                        question={question}
                                                        question_practice_skill={question_practice_skill}/>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                    <tr>
                                        <td><strong>Grading notes</strong></td>
                                        {question_practice_skills_for_question.map(question_practice_skill => {
                                            return (
                                                <td key={question_practice_skill.id}>{question_practice_skill.notes}</td>
                                            );
                                        })}
                                    </tr>
                                    <tr>
                                        <td><strong>Domain</strong></td>
                                        {question_practice_skills_for_question.map(question_practice_skill => {
                                            return (
                                                <td key={question_practice_skill.id}>{question_practice_skill.domain}</td>
                                            );
                                        })}
                                    </tr>
                                    {trs}
                                    </tbody>
                                </table>
                            </div>
                        );
                    } else {
                        return (
                            <div key={question.id}>Q{question.number} does not have any skills assigned</div>
                        );
                    }
                })}
            </div>
            <Summary/>
            <SummaryBonus/>
        </div>
    );
}
